import Container from 'react-bootstrap/Container';
import { FaFacebook, FaInstagram  } from "react-icons/fa";

const Footer = () =>{
    return (
    <Container fluid>
        <div className="copyright">&copy; 2024 Corporate. All Right Reserved.</div>
        <div className="socials">
        <ul>
            <li><a href="https://www.facebook.com/profile.php?id=100077448710092"><FaFacebook /></a></li>
            <li><a href="https://www.instagram.com/tqrllc/"><FaInstagram/></a></li>
        </ul>
        </div>
    </Container>
    );
}

export default Footer;