import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import tempImg1 from '../assets/images/about_us.jpg'

const aboutUsData = [
    {
      id: 1,
      icon: 'fas fa-clone',
      title: 'Over 10 Years Experience',
      description: 'With years of expertise and a passion for craftsmanship, we offer comprehensive renovation services. Revitalize outdated interiors, expand living spaces, or completely overhaul the layout of your home, our team of skilled professionals is dedicated to delivering exceptional results every step of the way.'
      
    },
    {
      id: 2,
      icon: 'fas fa-snowflake',
      title: 'Best Quality',
      description: 'At TQR, we are committed to delivering projects that meet the highest standards of quality, safety, and sustainability. Our robust professionalism, coupled with our experience and strict adherence to standards, ensures every project exceeds client expectations.'
    },
    {
      id: 3,
      icon: 'fas fa-plug',
      title: 'Profesional Standard',
      description: 'We uphold the highest professional standards, and gurantee exceptional quality, reliability, and integrity in every project we undertake. Trust us to deliver outstanding results that meet and exceed your expectations.'
    },
  ]



const AboutUs = () => {
  return (
    <section id="about_us" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={tempImg1} fluid/>
          </Col>
          <Col sm={6}>
            <Row>
              {
                aboutUsData.map(item => {
                    return (
                        <Row className='holder' key={item.id}>
                            <div className="icon">
                                <i className={item.icon}></i>
                            </div>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </Row>
                    );
                })
              }
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutUs;