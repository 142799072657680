import Carousel from 'react-bootstrap/Carousel';

var facebook_landing_page = "https://www.facebook.com/profile.php?id=100077448710092"

var carouselImageData = [
  {
    id: 1,
    image: require('../assets/images/temp_image1.jpg'),
    title: 'The perfect team for your next project',
    description: 'TQR has the required knowledge and experience for your next residental and or commercial project.',
    link: facebook_landing_page
    },
  {
    id: 2,
    image: require('../assets/images/temp_image2.jpg'),
    title: 'Peace of mind',
    description: 'We take the time to understand your goals, preferences, and budget constraints. From there, our team work closely with you to develop a customized plan that reflects your vision.',
    link:  facebook_landing_page
  },
  {
    id: 3,
    image: require('../assets/images/temp_image3.jpg'),
    title: 'Enjoy the Difference',
    description: 'We aim to provide the best quality in an affordable and timely manner.',
    link: facebook_landing_page
  }
]

const HomeCarousel = () => {
  return (
    <section id="home" className="hero-block">
       <Carousel fade>
          {
            carouselImageData.map(carouselImageDataItem => {
              return (
                <Carousel.Item key={carouselImageDataItem.id}>
                  <img
                    className="d-block w-100"
                    src={carouselImageDataItem.image}
                    alt={"slide " + carouselImageDataItem.id}
                  />
                  <Carousel.Caption>
                    <h2>{carouselImageDataItem.title}</h2>
                    <p>{carouselImageDataItem.description}</p>
                    <a className="btn btn-primary" href={carouselImageDataItem.link}>Learn More <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default HomeCarousel;