import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faHouse, faHammer, faPaintRoller } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const servicesData = [
  {
    id: 1,
    icon: faHouse,
    title: 'Interior Renovation',
    description: 'Transform the heart of your space with our interior renovation services. From breathing new life into outdated designs to creating modern, functional interiors, we specialize in bringing your vision to life.'
  },
  {
    id: 2,
    icon: faHammer,
    title: 'Design and Build',
    description: 'Experience seamless project execution with our Design and Build services. From concept to completion, we handle every aspect of your project, ensuring a cohesive and efficient process.'
  },
  {
    id: 3,
    icon: faPaintRoller,
    title: 'Exterior Renovation',
    description: "Our exterior services encompass a wide range of solutions for meticulous repairs, new lots, parking lots, etc. That's why we're your trusted partner and preferred choice for concrete, asphalt, masonry, and exterior construction services"
  }
]



const Services = () => {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">We provide a professional service for private and commercial customers.</div>
        </div>
        <Row>
          {
            servicesData.map(service => {
              return (
                <Col sm={4} className="holder" key={service.id}>
                  <div className="icon">
                    <i className='fas'><FontAwesomeIcon icon={service.icon}/></i>
                  </div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default Services;