import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const ProjectData = [
  {
    id: 1,
    image: require('../assets/images/finished_kitchen.jpg'),
    title: 'Kitchen',
    description: '',
    link: 'https://www.facebook.com/reel/1143340440040073'
  },
  {
    id: 2,
    image: require('../assets/images/interior_remodel.jpg'),
    title: 'Interior Remodel',
    description: '',
    link: 'https://www.facebook.com/reel/2643577372471593'
  },
  {
    id: 3,
    image: require('../assets/images/new_fence.jpg'),
    title: 'New Fence',
    description: '',
    link: 'https://fb.watch/s5pv0DRhjM/'
  }
]

const Projects = () => {
  return (
    <section id="projects" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Recent Projects</h2>
          <div className="subtitle">Take a look at some of our recent projects</div>
        </div>
        <Row>
          {
            ProjectData.map(project => {
              return (
                <Col sm={4} key={project.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={project.image} />
                      <Card.Body>
                        <Card.Title>{project.title}</Card.Title>
                        <Card.Text>
                          {project.description}
                        </Card.Text>
                        <a href={project.link} className="btn btn-primary">See More <i className="fas fa-chevron-right"></i></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default Projects;