import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/NavBar.react.js'
import HomeCarousel from './components/HomeCarousel.react.js';
import Services from './components/Services.react.js';
import Projects from './components/Projects.react.js';
import AboutUs from './components/AboutUs.react.js';
import Contact from './components/Contact.react.js';
import Footer from './components/Footer.react.js';
import React, { useState, useEffect } from "react";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
  }, []);

    const goTop = () =>{
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        });
    }
  
  return (
    <div className='App'>
      <header id='header'>
        <NavBar />
      </header>
      <main>
        <HomeCarousel />
        <AboutUs />
        <Services />
        <Projects />
        <Contact />
        <footer id="footer">
          <Footer />
        </footer>
        {
        showTopBtn && (
            <div className="go-top" onClick={goTop}></div>
        )
        }
      </main>
    </div>

  );
}

export default App;
