import Container from 'react-bootstrap/Container';
import { faPhone, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// create our App

const Contact = () =>{
  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">Get connected with us</div>
        </div>
      </Container>
      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"><FontAwesomeIcon icon={faEnvelope}/></i>
              ThiagoQualityRemodeling@gmail.com
            </li>
            <li>
              <i className="fas fa-phone"><FontAwesomeIcon icon={faPhone}/></i>
              +1 (681)-822-4089
            </li>
            <li>
              <i className="fas fa-map-marker-alt"><FontAwesomeIcon icon={faLocationDot}/></i>
              Denver, Colorado
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default Contact;